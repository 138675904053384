import React from "react"
import { graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"
import {Page} from '../../components/layout'
import {Copywrite} from '../../components/footer'
import SEO from '../../components/seo'



const Post =  ({ pageContext: {lang, title}, data: { mdx } }) => {

  const description = ''
  return <>
  <SEO {...{lang, title, description}}/>
  <Page width={[1, 1/2]}>
   <div className="mdx">
    <MDXRenderer>{mdx.body}</MDXRenderer>
  </div>
  <Copywrite/>
</Page></>
}

export default Post

export const query = graphql`
  query Datamanagement($lang: String!, $title: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { lang: { eq: $lang } }
    ) {
      frontmatter {
        title
      }
      body
    }
  }
`



